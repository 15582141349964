import GsuLoader from '@/components/GsuLoader'
import PageLayout from '@/compositions/PageLayout'
import { getConfig } from '@/lib/config'
import type { GetServerSideProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { useEffect } from 'react'

const {
  publicRuntimeConfig: {},
} = getConfig()

const Login: NextPage = (_props) => {
  // TODO: Temporarily take to prod site to login, and redirect back to where you were
  useEffect(() => {
    window.location.href = `https://getsetup.io/auth?returnTo=${encodeURIComponent(window.location.origin)}`
  }, [])
  return (
    <PageLayout>
      <Head>
        <title>Login</title>
      </Head>
      <GsuLoader consumeSpace />
    </PageLayout>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ locale = 'en-US' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default Login
